import { getGlobal } from "./getGlobal";
const global = getGlobal();
/**
 * Single export ensures that only one value is exported.
 *
 * This fixes the issue where Webpack sometimes calls an export multiple times for each package import. While using `externals` could resolve this, it becomes inconvenient in packages with explicit exports (multiple chunks).
 *
 * @example export const myClassInstance = singleExport('unique-key-string', new MyClass());
 */
export const singleExport = (key, value) => {
    const globalKey = `_singleExport_${key}`;
    const globalValue = global[globalKey];
    if (globalValue !== undefined)
        return globalValue;
    return global[globalKey] = value;
};
