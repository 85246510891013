import {EUserAuthenticationRights} from "server-app/dist/interfaces";
import {IAppRoute} from "../config/IAppRoute";
import BroadcasterTestIcon from '@mui/icons-material/CellTower';

export const routeBroadcasterConnectionTestPagePaths: IAppRoute = {
  menuId: '',
  icon: <BroadcasterTestIcon/>,
  title: 'Broadcaster Client ping/pong test page',
  description: "Test the connection to the server's broadcaster. Once connected, disconnect from the internet to see how it reconnects when you're back online.",
  routePath: '/app/test/broadcaster-client-connection',
  getRoutePath: () => routeBroadcasterConnectionTestPagePaths.routePath,
  userHasAllRights: [EUserAuthenticationRights.ADMINISTRATOR],
  userHasAnyOfRights: [],
  render: () => null,
};
