import { grayShades } from "./grayShades";
import { getGradientByColor } from "./utils/getGradientByColor";
export const MUIV5_PALETTE_DARK = {
    common: {
        black: '#000',
        white: '#fff',
    },
    background: {
        default: '#424242',
        paper: '#2c2c2c',
    },
    primary: {
        main: '#90caf9',
        light: 'rgb(166, 212, 250)',
        dark: 'rgb(100, 141, 174)',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
        main: '#f48fb1',
        light: 'rgb(246, 165, 192)',
        dark: 'rgb(170, 100, 123)',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
        main: '#f44336',
        light: 'rgb(246, 104, 94)',
        dark: 'rgb(170, 46, 37)',
        contrastText: '#fff',
    },
    warning: {
        main: '#ff9800',
        light: 'rgb(255, 171, 64)',
        dark: 'rgb(178, 112, 0)',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
        main: '#2196f3',
        light: 'rgb(71, 145, 219)',
        dark: 'rgb(17, 82, 147)',
        contrastText: '#fff',
    },
    success: {
        main: '#4caf50',
        light: 'rgb(111, 191, 115)',
        dark: 'rgb(53, 122, 56)',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    grayShades: {
        gray0: grayShades[9],
        gray1: grayShades[8],
        gray2: grayShades[7],
        gray3: grayShades[6],
        gray4: grayShades[5],
        gray5: grayShades[4],
        gray6: grayShades[3],
        gray7: grayShades[2],
        gray8: grayShades[1],
        gray9: grayShades[0],
    },
    gradients: {
        paleBlue: {
            start: "#376694",
            end: "#11375b",
            contrast: grayShades[0],
        },
        paleRed: {
            start: "#943737",
            end: "#5b1111",
            contrast: grayShades[0],
        },
        paleGreen: {
            start: "#379443",
            end: "#115b2b",
            contrast: grayShades[0],
        },
        paleOrange: {
            start: "#946737",
            end: "#5b3711",
            contrast: grayShades[0],
        },
        byColor: color => getGradientByColor(MUIV5_PALETTE_DARK, color),
    },
};
