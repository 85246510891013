import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IBroadcasterConnectionTestPageProps} from "./BroadcasterConnectionTestPage";

const BroadcasterConnectionTestPageLazy = lazy(() => import("./BroadcasterConnectionTestPage"));

export const BroadcasterConnectionTestPage = connect((props: IBroadcasterConnectionTestPageProps) => (
  <LazyLoadComponent>
    <BroadcasterConnectionTestPageLazy {...props}/>
  </LazyLoadComponent>
));
