import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, } from "react";
import { Icon as Iconify } from "@iconify/react";
import { Box } from "../Box";
import { HtmlContent } from "../HtmlContent";
export const IconViewer = (props) => {
    const { show = true, sx = {}, sxSvg: sxSvgUser = {}, Icon, MuiIcon, emoji, svgElement, svgScript, IconifyIcon, image, width: userWidth, height: userHeight, title, } = props;
    const width = userWidth || userHeight;
    const height = userHeight
        ? userHeight
        : userWidth === "100%"
            ? "auto"
            : userWidth;
    useEffect(() => {
        const imagesCount = [
            MuiIcon,
            Icon,
            emoji,
            svgElement,
            svgScript,
            IconifyIcon,
            image,
        ].filter(Boolean).length;
        if (imagesCount > 1) {
            console.error('IconViewer error 20230525110318: More than one image has been provided, only one is supported', {
                Icon,
                MuiIcon,
                emoji,
                svgElement,
                svgScript,
                IconifyIcon,
                image,
            });
        }
    }, []);
    if (!show)
        return null;
    const wrapper = (content) => {
        const sxSvgBase = width || height
            ? {
                width,
                height,
            }
            : { fontSize: 'inherit' };
        const sxSvgApply = Object.assign(Object.assign({}, sxSvgBase), sxSvgUser);
        return (_jsx(Box, { dataComponentName: "IconViewer", inline: width === "100%" ? undefined : "flex", sx: Object.assign(Object.assign({}, sx), { 'svg': sxSvgApply }), title: title, children: content }));
    };
    if (svgElement)
        return wrapper(svgElement);
    if (svgScript) {
        return wrapper(_jsx(HtmlContent, { component: "span", html: svgScript }));
    }
    if (image) {
        return wrapper(_jsx("img", { src: image, alt: title, style: {
                width: size(width),
                height: size(height),
            } }));
    }
    if (Icon) {
        return wrapper(_jsx(Icon, { width: width, height: height }));
    }
    if (MuiIcon)
        return wrapper(_jsx(MuiIcon, {}));
    if (IconifyIcon)
        return wrapper(_jsx(Iconify, { icon: IconifyIcon }));
    if (emoji) {
        return (_jsx("span", { style: { fontSize: `${width}px` }, children: emoji }));
    }
    return null;
};
const size = (value) => typeof value === "undefined"
    ? undefined
    : typeof value === "string"
        ? value
        : value + 'px';
