import {ICreateStoreAPI} from "dynadux";
import {dynaError} from "dyna-error";

import {API_PATH_apiAuthCurrentUserInfoGet} from "server-app/dist/interfaces";

import {
  getOnline,
  isCurrentAddressSame,
} from "utils-library/dist/web";

import {EThemeSize} from "ui-components/dist/ThemeProvider";

import {INavigateTo} from "../interfaces/INavigateTo";
import {
  EUserAuthAction,
  ISIGN_OUT_payload,
} from "../../user-authnentication/state/userAuthSection";

import {IFetchRequest} from "../../../api/apiFetch";

import {appConfig} from "../config/appConfig";
import {apiFetch} from "../../../api/apiFetch";

export enum EAppAction {
  SET_ONLINE = "APP__SET_ONLINE",                     // Payload boolean
  RESET_APP = "APP__RESET_APP",                       // Payload nothing
  NAVIGATE_TO = "APP__NAVIGATE_TO",                   // Payload INavigateTo
  SET_TITLE = "APP__SET_TITLE",                       // Payload string
  SET_MENU_ID = "APP__SET_MENU_ID",                   // Payload string
  SET_THEME = "APP__SET_THEME",                       // Payload IAppAction_SET_THEME_payload
  DISABLE_THEME_CHANGE = "APP__DISABLE_THEME_CHANGE",
  SET_THEME_SIZE = "APP__SET_THEME_SIZE",             // Payload EThemeSize
  SET_LOADING_PAGE = "APP__SET_LOADING_PAGE",         // Payload boolean
}

export interface IAppAction_SET_THEME_payload {
  theme: EAppTheme;
}

export interface IAppAction_ENABLE_SECTION_BY_USER_RIGHTS_payload {
  signedIn: boolean;
  rights: string[];
}

export enum EAppTheme {
  LIGHT = "LIGHT",
  DARK = "DARK",
}

export interface IAppSection {
  online: boolean;
  companyId: string;
  title: string;
  menuId: string;
  theme: EAppTheme;
  disableThemeChange: boolean;
  themeSize: EThemeSize;
  isLoadingPage: boolean;
}

export const appSectionInitialState = (): IAppSection => ({
  online: getOnline(),
  companyId: appConfig.companyId,
  themeSize: loadThemeSize(),
  title: '',
  menuId: '',
  theme: loadTheme(),
  disableThemeChange: false,
  isLoadingPage: appConfig.initialIsLoadingPage || false,
});

export const createAppSection = (
  {
    store,
    navigateTo,
  }: {
    store: ICreateStoreAPI;
    navigateTo: (navigateTo: INavigateTo) => void;
  },
) => {
  const section = store.createSection<IAppSection>({
    section: 'app',
    initialState: appSectionInitialState(),
    reducers: {
      [EAppAction.SET_ONLINE]: ({payload}): Partial<IAppSection> => {
        const online: boolean = payload;
        return {online};
      },
      [EAppAction.RESET_APP]: (): IAppSection => {
        return appSectionInitialState();
      },
      [EAppAction.NAVIGATE_TO]: ({payload}): void => {
        const navigateToConfig: INavigateTo = payload;
        navigateTo(navigateToConfig);
      },
      [EAppAction.SET_TITLE]: (
        {
          payload,
          state: {title: currentTitle},
          blockChange,
        },
      ): Partial<IAppSection> | undefined => {
        const title_: string = payload;
        const title = [appConfig.appName, title_].join(' - ');
        if (currentTitle === title) {
          blockChange();
          return undefined;
        }
        return {title};
      },
      [EAppAction.SET_MENU_ID]: (
        {
          payload,
          state: {menuId: currentMenuId},
        },
      ): Partial<IAppSection> | undefined => {
        const menuId: string = payload;
        if (menuId === currentMenuId) return undefined;
        return {menuId: menuId || ''};
      },
      [EAppAction.SET_THEME]: ({payload}): Partial<IAppSection> => {
        const {theme}: IAppAction_SET_THEME_payload = payload;
        saveTheme(theme);
        return {theme};
      },
      [EAppAction.DISABLE_THEME_CHANGE]: (
        {
          payload,
          state,
        },
      ): Partial<IAppSection> | undefined => {
        const disableThemeChange: boolean = payload;
        if (state.disableThemeChange === disableThemeChange) return; // Exit, there is no change
        return {disableThemeChange};
      },
      [EAppAction.SET_THEME_SIZE]: (
        {
          payload,
          state,
        },
      ): undefined | Partial<IAppSection> | void => {
        const themeSize: EThemeSize = payload;
        if (loadThemeSize() === themeSize && state.themeSize === themeSize) return;
        saveThemeSize(themeSize);
        return {themeSize: themeSize};
      },
      [EAppAction.SET_LOADING_PAGE]: ({payload}): undefined | Partial<IAppSection> | void => {
        const isLoadingPage: boolean = payload;
        return {isLoadingPage};
      },
    },
  });

  apiFetch.on401AccessDenied = (fetchRequest: IFetchRequest) => {
    if (fetchRequest.path === API_PATH_apiAuthCurrentUserInfoGet) return;
    section.dispatch<ISIGN_OUT_payload>(
      EUserAuthAction.SIGN_OUT,
      {
        networkSignOff: false,
        authError: dynaError({
          code: 201707160836,
          message: '401 on a XHR call, forced the application to reset and sign out',
          userMessage: 'You had been logged out.',
        }),
      },
    );
  };

  const updateOnlineStatus = (): void => section.dispatch<boolean>(EAppAction.SET_ONLINE, getOnline());
  window.addEventListener('online', updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);

  (window as any)._debug_onLine = (online: boolean): void => {
    localStorage.setItem('_debug_online', online.toString());
    updateOnlineStatus();
  };

  return {
    get state() {
      return section.state;
    },
    actions: {
      resetApp: (): void => section.dispatch<void>(EAppAction.RESET_APP),
      setTitle: (title: string) => section.dispatch<string>(EAppAction.SET_TITLE, title),
      setMenuId: (menuId: string) => section.dispatch<string>(EAppAction.SET_MENU_ID, menuId),
      setTheme: (theme: EAppTheme) => section.dispatch<IAppAction_SET_THEME_payload>(EAppAction.SET_THEME, {theme}),
      navigateTo: (navigateToConfig: INavigateTo): void => {
        if (navigateToConfig.inNewWindow) {
          window.open(navigateToConfig.url, '_blank');
          return;
        }
        if (isCurrentAddressSame(navigateToConfig.url)) return;
        section.dispatch<INavigateTo>(EAppAction.NAVIGATE_TO, navigateToConfig);
      },
      navigateBack: (): void=> section.dispatch<INavigateTo>(EAppAction.NAVIGATE_TO, {url: '<'}),
      disableThemeChange: (disableThemeChange: boolean) => section.dispatch<boolean>(EAppAction.DISABLE_THEME_CHANGE, disableThemeChange),
      setThemeSize: (size: EThemeSize) => section.dispatch<EThemeSize>(EAppAction.SET_THEME_SIZE, size),
      setIsLoadingPage: (isLoadingPage: boolean): void => section.dispatch<boolean>(EAppAction.SET_LOADING_PAGE, isLoadingPage),
    },
  };
};

const saveTheme = (theme: EAppTheme): void => localStorage.setItem('app--theme', theme);
const loadTheme = (): EAppTheme => localStorage.getItem('app--theme') as any || EAppTheme.LIGHT;

const saveThemeSize = (size: EThemeSize): void => localStorage.setItem('app--themeSize', size);
const loadThemeSize = (): EThemeSize => localStorage.getItem('app--themeSize') as any || EThemeSize.MEDIUM;
