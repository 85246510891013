import {connect} from "react-dynadux";

import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";

import {IUserManagementMainPageProps} from "./UserManagementMainPage";

const UserManagementMainPageLazy = lazy(() => import("./UserManagementMainPage"));

export const UserManagementMainPage = connect((props: IUserManagementMainPageProps) => (
  <LazyLoadComponent>
    <UserManagementMainPageLazy {...props}/>
  </LazyLoadComponent>
));
