import {IDynaCMSArticleAddress} from "server-app/dist/interfaces";

import {IAppRoute} from "../../../application/config/IAppRoute";

import {DynaCMSArticleViewerMultiLanguage} from "../DynaCMSArticleViewerMultiLanguage";

export const routeDynaCMSArticle = (
  {
    title = "Loading...",
    path,
    multiLangArticles,
  }: {
    title?: string; // This is for a short time, till the article got loaded
    path: string;
    multiLangArticles: IDynaCMSArticleAddress[];
  },
): IAppRoute => ({
  title,
  routePath: path.startsWith('/') ? path : '/' + path,
  getRoutePath: () => path.startsWith('/') ? path : '/' + path,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => <DynaCMSArticleViewerMultiLanguage multiLangArticles={multiLangArticles}/>,
});
