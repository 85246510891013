
import {EDynaCMSRights} from "server-app/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

import SettingsIcon from "@mui/icons-material/Tune";

export const routeDynaCMSSettingsPaths: IAppRoute = {
  icon: <SettingsIcon/>,
  title: "CMS Settings",
  description: "Content management system settings",
  routePath: '/dyna-cms/settings',
  getRoutePath: () => routeDynaCMSSettingsPaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [EDynaCMSRights.CMS_SETTINGS_CHANGE],
  requiresLatestClientVersion: true,
  render: () => null,
};
