import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
import { dynaSwitchEnum } from "dyna-switch";
import MuiButton from '@mui/material/Button';
import { getDataComponentName, } from "../ui-interfaces";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { Box } from "../Box";
import { Ghost } from "../Ghost";
import { Link, ELinkUnderline, } from "../Link";
import { IconViewer } from "../IconViewer";
import { useBreakpointDevice } from "../useBreakpointDevice";
import { ECSSDuration, sxTransition, } from "../sxTransition";
import { useTheme, } from "../ThemeProvider";
import { convertSizeToPx } from "../utils";
export var EButtonVariant;
(function (EButtonVariant) {
    EButtonVariant["OUTLINED"] = "outlined";
    EButtonVariant["CONTAINED"] = "contained";
    EButtonVariant["TRANSPARENT"] = "TRANSPARENT";
})(EButtonVariant || (EButtonVariant = {}));
export var EButtonDisplay;
(function (EButtonDisplay) {
    EButtonDisplay["INLINE"] = "INLINE";
    EButtonDisplay["BLOCK"] = "BLOCK";
})(EButtonDisplay || (EButtonDisplay = {}));
export var EButtonIconPosition;
(function (EButtonIconPosition) {
    EButtonIconPosition["LEFT"] = "LEFT";
    EButtonIconPosition["RIGHT"] = "RIGHT";
})(EButtonIconPosition || (EButtonIconPosition = {}));
export var EButtonTextAlign;
(function (EButtonTextAlign) {
    EButtonTextAlign["LEFT"] = "left";
    EButtonTextAlign["CENTER"] = "center";
    EButtonTextAlign["RIGHT"] = "right";
})(EButtonTextAlign || (EButtonTextAlign = {}));
export var EButtonSize;
(function (EButtonSize) {
    EButtonSize["XXXSMALL"] = "XXXSMALL";
    EButtonSize["XXSMALL"] = "XXSMALL";
    EButtonSize["XSMALL"] = "XSMALL";
    EButtonSize["SMALL"] = "SMALL";
    EButtonSize["MEDIUM"] = "MEDIUM";
    EButtonSize["LARGE"] = "LARGE";
    EButtonSize["XLARGE"] = "XLARGE";
    EButtonSize["XXLARGE"] = "XXLARGE";
})(EButtonSize || (EButtonSize = {}));
export var EButtonColor;
(function (EButtonColor) {
    EButtonColor["PRIMARY"] = "PRIMARY";
    EButtonColor["SECONDARY"] = "SECONDARY";
    EButtonColor["SUCCESS"] = "SUCCESS";
    EButtonColor["ERROR"] = "ERROR";
    EButtonColor["INFO"] = "INFO";
    EButtonColor["WARNING"] = "WARNING";
    EButtonColor["WHITE"] = "WHITE";
})(EButtonColor || (EButtonColor = {}));
export const Button = forwardRef(function Button(props, ref) {
    const { dataComponentName, show = true, sx: userSx, fullWidth = false, display = EButtonDisplay.INLINE, variant = EButtonVariant.CONTAINED, ghost = false, color = EButtonColor.PRIMARY, fontFamilyInherit = false, fontWeightBold = true, labelFontSize, descriptionFontSize, textAlign = EButtonTextAlign.CENTER, size = EButtonSize.MEDIUM, padding, noFontSize = false, upperCase = false, disabled = false, icon, Icon, iconPosition = EButtonIconPosition.LEFT, type, title, description, hidden = false, autoFocus, ariaLabel, children, wrap = false, hideLabelOnMobile = false, hideLabelOnTablet = false, href, hrefNewWindow = false, onClick, } = props;
    const theme = useTheme();
    const { isMobile, isTablet, } = useBreakpointDevice();
    if (!show || hidden)
        return null;
    const showChildrenDescription = (Array.isArray(children)
        ? children.filter(Boolean).length > 0
        : !!children)
        && (!isMobile || !hideLabelOnMobile)
        && (!isTablet || !hideLabelOnTablet);
    const actualSize = parseFloat(dynaSwitchEnum(size, {
        [EButtonSize.XXXSMALL]: theme.spacing(1),
        [EButtonSize.XXSMALL]: theme.spacing(1.25),
        [EButtonSize.XSMALL]: theme.spacing(1.5),
        [EButtonSize.SMALL]: theme.spacing(1.75),
        [EButtonSize.MEDIUM]: theme.spacing(1.9),
        [EButtonSize.LARGE]: theme.spacing(2.5),
        [EButtonSize.XLARGE]: theme.spacing(3),
        [EButtonSize.XXLARGE]: theme.spacing(4),
    }));
    const sx = Object.assign({ position: 'relative', width: fullWidth ? '100%' : undefined, color: (() => {
            if (disabled)
                return theme.palette.grayShades.gray8;
            if (color === EButtonColor.WHITE) {
                return dynaSwitchEnum(variant, {
                    [EButtonVariant.OUTLINED]: theme.palette.primary.main,
                    [EButtonVariant.CONTAINED]: theme.palette.grey["900"],
                    [EButtonVariant.TRANSPARENT]: theme.palette.primary.dark,
                });
            }
            return undefined;
        })(), backgroundColor: (() => {
            if (disabled)
                return theme.palette.grayShades.gray4;
            if (color === EButtonColor.WHITE && variant === EButtonVariant.CONTAINED)
                return theme.palette.grey["400"];
            return undefined;
        })(), '&: hover': {
            backgroundColor: (() => {
                if (color === EButtonColor.WHITE && variant === EButtonVariant.CONTAINED)
                    return theme.palette.grey["200"];
                return undefined;
            })(),
            borderColor: (() => {
                if (color === EButtonColor.WHITE && variant === EButtonVariant.OUTLINED)
                    return theme.palette.grey["200"];
                return undefined;
            })(),
        }, borderRadius: variant === EButtonVariant.TRANSPARENT
            ? 0
            : undefined, whiteSpace: wrap
            ? 'normal'
            : 'nowrap', px: (() => {
            if (padding !== undefined)
                return padding;
            return actualSize + 'px';
        })(), py: (() => {
            if (padding !== undefined)
                return padding;
            return (actualSize / 2) + 'px';
        })(), fontFamily: fontFamilyInherit
            ? 'inherit !important'
            : undefined, fontWeight: fontWeightBold
            ? 'bold !important'
            : 'normal !important', justifyContent: dynaSwitchEnum(textAlign, {
            [EButtonTextAlign.LEFT]: 'flex-start',
            [EButtonTextAlign.CENTER]: 'center',
            [EButtonTextAlign.RIGHT]: 'flex-end',
        }), textTransform: upperCase
            ? 'uppercase'
            : 'none', opacity: disabled ? 0.75 : undefined, transition: theme => sxTransition(theme, [
            'color',
            "background",
            "background-color",
            "opacity",
        ], ECSSDuration.SHORT), '& > div': {
            textAlign: dynaSwitchEnum(textAlign, {
                [EButtonTextAlign.LEFT]: 'left',
                [EButtonTextAlign.CENTER]: 'center',
                [EButtonTextAlign.RIGHT]: 'right',
            }),
        } }, userSx);
    const style = {
        // Style overrides MUI's base attrs easily, while sx not
        fontSize: noFontSize || !showChildrenDescription
            ? 0
            : undefined,
        textTransform: upperCase
            ? 'uppercase'
            : 'none',
        lineHeight: "initial",
        pointerEvents: disabled ? 'none' : undefined,
    };
    const applyButtonVariant = (() => {
        if (variant === EButtonVariant.TRANSPARENT)
            return "text";
        return variant;
    })();
    const applyButtonColor = dynaSwitchEnum(color, {
        [EButtonColor.PRIMARY]: 'primary',
        [EButtonColor.SECONDARY]: 'secondary',
        [EButtonColor.SUCCESS]: 'success',
        [EButtonColor.ERROR]: 'error',
        [EButtonColor.INFO]: 'info',
        [EButtonColor.WARNING]: 'warning',
        [EButtonColor.WHITE]: undefined,
    });
    const iconContent = (_jsxs(_Fragment, { children: [_jsx(Box
            // Old image support (React.JSX.Element)
            , { 
                // Old image support (React.JSX.Element)
                show: !!icon, dataComponentName: "OLDIconContainer", component: showChildrenDescription ? "span" : "div", sx: {
                    'svg': {
                        width: (actualSize * 2) + 'px',
                        height: (actualSize * 2) + 'px',
                    },
                }, style: { lineHeight: 0 }, children: icon }), _jsx(IconViewer, { Icon: Icon, height: actualSize * 2 })] }));
    const handleClick = (event) => {
        if (disabled)
            return;
        onClick === null || onClick === void 0 ? void 0 : onClick(event);
    };
    let button = (_jsxs(MuiButton, { "data-component-name": getDataComponentName(dataComponentName, "Button"), sx: sx, ref: ref, style: style, variant: applyButtonVariant, color: applyButtonColor, size: dynaSwitchEnum(size, {
            [EButtonSize.XXXSMALL]: 'small',
            [EButtonSize.XXSMALL]: 'small',
            [EButtonSize.XSMALL]: 'small',
            [EButtonSize.SMALL]: 'small',
            [EButtonSize.MEDIUM]: 'medium',
            [EButtonSize.LARGE]: 'large',
            [EButtonSize.XLARGE]: 'large',
            [EButtonSize.XXLARGE]: 'large',
        }), autoFocus: autoFocus, type: type, title: title, "aria-label": ariaLabel, onClick: handleClick, children: [_jsx(Ghost, { show: ghost }), _jsxs(FlexContainerHorizontal, { dataComponentName: "ButtonContent", alignVertical: description ? "top" : "middle", sx: { width: fullWidth ? '100%' : undefined }, children: [_jsx(FlexItemMin, { sx: {
                            display: 'inherit',
                            marginRight: showChildrenDescription
                                ? (actualSize / 2) + 'px'
                                : undefined,
                        }, show: (!!icon || !!Icon) && iconPosition === EButtonIconPosition.LEFT, children: iconContent }), _jsxs(FlexItemMax, { sx: { textAlign: fullWidth || description ? 'left' : 'undefined' }, show: showChildrenDescription, children: [_jsx(Box, { lowLevelClassName: "button-label", sx: {
                                    fontSize: theme => labelFontSize
                                        ? convertSizeToPx(theme, labelFontSize)
                                        : actualSize + 'px',
                                }, children: children }), _jsx(Box, { show: !!description, lowLevelClassName: "button-description", sx: {
                                    fontSize: theme => convertSizeToPx(theme, descriptionFontSize || theme.typography.fontSize),
                                    fontWeight: 'normal',
                                    whiteSpace: 'pre-wrap',
                                    lineHeight: 1,
                                    marginBottom: theme => theme.spacing(0.5),
                                }, children: description })] }), _jsx(FlexItemMin, { sx: { display: 'inherit' }, show: (!!icon || !!Icon) && iconPosition === EButtonIconPosition.RIGHT, children: iconContent })] })] }));
    if (display === EButtonDisplay.BLOCK)
        button = _jsx("div", { children: button });
    return href
        ? (_jsx(Link, { href: href, underline: ELinkUnderline.NONE, target: hrefNewWindow ? '_blank' : undefined, rel: "noreferrer", sx: { textDecoration: 'none' }, children: button }))
        : button;
});
