import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { Box } from "../Box";
import { ContainerRelative, ContainerAbsolut, } from "../ContainerRelativeAbsolut";
import { IsLoading } from "../IsLoading";
import { IconButton } from "../IconButton";
import { Typography } from "../Typography";
import { createIcon, } from "../IconComponent";
import { IconViewer } from "../IconViewer";
import { alpha, } from "../ThemeProvider";
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import { getDataComponentName } from "../web-utils";
import { useBreakpointDevice } from "../useBreakpointDevice";
export const ContainerSection = (props) => {
    const { dataComponentName, sx, show = true, h = 1, fullHeight, isLoading = false, Icon, SubIcon, title, subtitle, description, topRightContainer, children, nestedChildren = true, onBackButtonClick, } = props;
    const { isMobile, isTablet, } = useBreakpointDevice();
    const isSmallDevice = isMobile || isTablet;
    const iconFactor = isSmallDevice
        ? 2
        : 5 + 1 - h;
    if (!show)
        return null;
    const applyChildren = (_jsx(IsLoading, { isLoading: isLoading, children: children }));
    return (_jsxs(_Fragment, { children: [_jsxs(FlexContainerHorizontal, { dataComponentName: getDataComponentName(dataComponentName, 'ContainerSection'), sx: sx, fullHeight: fullHeight, spacing: 1, children: [_jsx(FlexItemMin, { show: !!Icon, children: _jsxs(ContainerRelative, { sx: { pt: isSmallDevice ? 1 : undefined }, children: [_jsx(IconViewer, { Icon: Icon, width: iconFactor * 24 }), _jsx(ContainerAbsolut, { show: !!SubIcon, sx: {
                                        right: theme => theme.spacing(-1),
                                        bottom: 0,
                                        padding: theme => theme.spacing(iconFactor / 5),
                                        backgroundColor: theme => theme.palette.background.default,
                                        borderRadius: (iconFactor * 12) + 'px',
                                        border: '1px solid',
                                        borderColor: theme => alpha(theme.palette.text.secondary, 0.25),
                                        fontSize: 0,
                                    }, children: _jsx(IconViewer, { Icon: SubIcon, width: iconFactor * 5 }) })] }) }), _jsxs(FlexItemMax, { sx: { paddingTop: theme => theme.spacing(1) }, children: [_jsxs(FlexContainerHorizontal, { alignVertical: "top", children: [_jsxs(FlexItemMax, { children: [_jsxs(FlexContainerHorizontal, { children: [_jsx(FlexItemMin, { children: _jsx(IconButton, { show: !!onBackButtonClick, ariaLabel: "Back", tooltip: "Go back", Icon: createIcon.byMuiIcon(BackIcon, {
                                                                position: "relative",
                                                                left: -2,
                                                            }), onClick: onBackButtonClick }) }), _jsx(FlexItemMax, { children: _jsx(Typography, { v: `h${h}`, sx: { pt: 1 }, children: title }) })] }), _jsx(Typography, { v: `h${h + 1}`, children: subtitle }), _jsx(Typography, { v: "body1", children: description })] }), _jsx(FlexItemMin, { children: topRightContainer })] }), !!nestedChildren && applyChildren] })] }), !nestedChildren && (_jsx(Box, { dataComponentName: getDataComponentName(dataComponentName, 'ContainerSection'), children: applyChildren }))] }));
};
