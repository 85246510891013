import { grayShades } from "./grayShades";
import { getGradientByColor } from "./utils/getGradientByColor";
export const MUIV4_PALETTE_DARK = {
    appMainColor: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#303f9f',
        contrastText: '#fff',
    },
    primary: {
        light: '#96cdf6',
        main: '#6fbbf8',
        dark: '#42A5F5',
        contrastText: '#1C1C1C',
    },
    secondary: {
        light: '#ecc8f1',
        main: '#CE93D8',
        dark: '#AB47BC',
        contrastText: '#1C1C1C',
    },
    error: {
        light: '#E57373',
        main: '#F44337',
        dark: '#D32F2F',
        contrastText: '#1C1C1C',
    },
    warning: {
        light: '#ffb74d',
        main: '#ff9800',
        dark: '#f57c00',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
        light: '#64b5f6',
        main: '#2196f3',
        dark: '#303f9f',
        contrastText: '#1C1C1C',
    },
    success: {
        light: '#81c784',
        main: '#4caf50',
        dark: '#388e3c',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    text: {
        primary: '#fff',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(255, 255, 255, 0.5)',
    },
    background: {
        default: '#424242',
        paper: '#2c2c2c',
    },
    grayShades: {
        gray0: grayShades[9],
        gray1: grayShades[8],
        gray2: grayShades[7],
        gray3: grayShades[6],
        gray4: grayShades[5],
        gray5: grayShades[4],
        gray6: grayShades[3],
        gray7: grayShades[2],
        gray8: grayShades[1],
        gray9: grayShades[0],
    },
    gradients: {
        paleBlue: {
            start: "#376694", // Deep pale blue
            end: "#11375b", // Darker shade of pale blue
            contrast: grayShades[0],
        },
        paleRed: {
            start: "#943737", // Deep pale red
            end: "#5b1111", // Darker shade of pale red
            contrast: grayShades[0],
        },
        paleGreen: {
            start: "#379443", // Deep pale green
            end: "#115b2b", // Darker shade of pale green
            contrast: grayShades[0],
        },
        paleOrange: {
            start: "#a1774a", // Deep pale orange
            end: "#785836", // Darker shade of pale orange
            contrast: grayShades[0],
        },
        byColor: color => getGradientByColor(MUIV4_PALETTE_DARK, color),
    },
};
