import {EUserAuthenticationRights} from "server-app/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

export const routeUserEditPagePaths: IAppRoute<{
  userId: string;
}> = {
  menuId: '',
  title: 'Edit User',
  routePath: '/users-management/manage-user/:userId',
  getRoutePath: ({userId} = {userId: ''}) =>
    routeUserEditPagePaths.routePath
      .replace(':userId', userId),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EUserAuthenticationRights.ADMINISTRATOR,
    EUserAuthenticationRights.SYSTEM_ADMINISTRATOR,
  ],
  render: () => null,
};
