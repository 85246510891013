import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {ICheckAppVersionProps} from "./CheckAppVersion";

const CheckAppVersionLazy = lazy(() => import("./CheckAppVersion"));

export const CheckAppVersion = connect((props: ICheckAppVersionProps) => (
  <LazyLoadComponent>
    <CheckAppVersionLazy {...props}/>
  </LazyLoadComponent>
));
