import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {IAppVersionProps} from "./AppVersion";

const AppVersionLazy = lazy(() => import("./AppVersion"));

export const AppVersion = (props: IAppVersionProps) => (
  <LazyLoadComponent>
    <AppVersionLazy {...props}/>
  </LazyLoadComponent>
);
