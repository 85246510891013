import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "ui-components/dist/LazyLoadComponent";
import {ISignInPageProps} from "./SignInPage";

const SignInPageLazy = lazy(() => import("./SignInPage"));

export const SignInPage = connect((props: ISignInPageProps) => (
  <LazyLoadComponent>
    <SignInPageLazy {...props}/>
  </LazyLoadComponent>
));
