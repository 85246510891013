import {DynaLocalStorageData} from "dyna-local-storage";
import {guid} from "dyna-guid";

interface ILSData {
  userIdHash: string;
  sessionsId: string;
}

class LocalSessionsIdManager {
  private readonly ls = new DynaLocalStorageData<ILSData>(
    'app_Auth__localSessionId',
    {
      userIdHash: '',
      sessionsId: '',
    },
  );

  public clear(): void {
    this.ls.data.userIdHash = '';
    this.ls.data.sessionsId = '';
    this.ls.save();
  }

  public getSessionId(): string {
    return this.ls.data.sessionsId;
  }

  public getSessionIdByUser(userIdHash: string): string {
    if (this.ls.data.userIdHash === userIdHash) return this.ls.data.sessionsId;

    // At this point, the user is different!
    this.ls.data.userIdHash = userIdHash;
    this.ls.data.sessionsId = guid();
    this.ls.save();
    return this.ls.data.sessionsId;
  }
}

export const localSessionIdManager = new LocalSessionsIdManager();
