import {getCompanyElements} from "../../../../companies";

import {routesUserManagement} from "../../../user-management";
import {routesUserAuthentication} from "../../../user-authnentication";
import {routesDynaCMSManagement} from "../../../dyna-cms";
import {routesFileStorage} from "../../../file-storage";
import {routesShortURL} from "../../../short-url/routes";

import {routesAppCore} from "../../routes";
import {IAppRoute} from "../IAppRoute";


export const getAppRoutes = async (): Promise<IAppRoute[]> => {
  const {routes} = await getCompanyElements();
  const appRoutes: IAppRoute<any>[] = [
    //#region "App starter"
    ...routesAppCore,
    //#endregion "App starter"
    //#region "Core routes"
    ...routesUserManagement,
    ...routesUserAuthentication,
    ...routesDynaCMSManagement,
    ...routesFileStorage,
    ...routesShortURL,
    //#endregion "Core routes"
    //#region "Company's"
    ...routes,
    //#endregion "Company's"
  ];

  return appRoutes
    .reduce((acc: IAppRoute[], route) => {
      // Override exact pages.
      const findIndex = acc.findIndex(scanRoute => scanRoute.routePath === route.routePath);
      if (findIndex > -1) acc[findIndex] = route; else acc.push(route);
      return acc;
    }, []);
};
