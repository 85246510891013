import {IAppRoute} from "../../application/config/IAppRoute";

import {routeUserManagementMainPage} from "./routeUserManagementMainPage";
import {routeListUsersPage} from "./routeListUsersPage";
import {routeUserEditPage} from "./routeUserEditPage";

export const routesUserManagement: IAppRoute<any>[] = [
  routeUserManagementMainPage,
  routeListUsersPage,
  routeUserEditPage,
];
