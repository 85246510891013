import { getDefaultDBEntityDocument, } from "utils-library/dist/db-entity-interfaces";
export const getDefaultUser = () => getDefaultDBEntityDocument({
    authUserId: '',
    idHash: '',
    displayName: '',
    title: '',
    email: '',
    firstName: '',
    lastName: '',
    rights: [],
});
