import {IAppRoute} from "../../application/config/IAppRoute";

import {UserManagementListPage} from "../pages/UserManagementListPage";

import {routeListUsersPagePaths} from "./routeListUsersPage.paths";

export const routeListUsersPage: IAppRoute<{
  search?: string;
}> = {
  ...routeListUsersPagePaths,
  render: ({pathParams}) => (
    <UserManagementListPage
      search={pathParams.search || ''}
    />
  ),
};
