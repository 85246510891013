var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { dynaError, } from "dyna-error";
/**
 * Promise Execution with Fallback
 *
 * # Description
 *
 * This utility function safely executes a promise, ensuring that if it fails, a default value is returned instead.
 * It also captures any error details for debugging purposes.
 *
 * Useful for handling API calls and other asynchronous operations.
 *
 * # Key benefits
 * - direct execution of the promise
 * - constant value
 * - always return a value even the default
 * - no try/catch blocks
 * - easy consumption of the error
 * - error is always a IDynaError
 *
 * # Example Usage
 * ```typescript
 * interface IWeather { temperature: number };
 *
 * const getWeather = async (city: string): Promise<IWeather> => {
 *   if (city === "invalid") throw new Error("City not found");
 *   return { temperature: 22 };
 * };
 *
 * const result = await promiseResult({
 *   promise: getWeather("nyc"),
 *   default: { temperature: -1 },
 * });
 *
 * console.log(result.result); // { temperature: 22 } or { temperature: -1 } if it fails
 * console.log(result.error?.message); // Error message if an error occurred
 * ```
 */
export const promiseResult = (_a) => __awaiter(void 0, [_a], void 0, function* ({ promise, default: _default, }) {
    try {
        const result = yield promise;
        return { result };
    }
    catch (error) {
        return {
            result: _default,
            error: dynaError(error),
        };
    }
});
