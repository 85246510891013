import {IAppRoute} from "../config/IAppRoute";
import {AppVersion} from "../public-components";

export const routeAppVersion: IAppRoute = {
  title: 'App version',
  routePath: '/app/version',
  getRoutePath: () => routeAppVersion.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => <AppVersion/>,
};
