import { dynaSwitchEnum } from "dyna-switch";
import { EGradientColor, } from "../../interfaces";
import { grayShades } from "../grayShades";
export const getGradientByColor = (palette, gradientColor) => {
    var _a, _b, _c, _d;
    return dynaSwitchEnum(gradientColor, {
        [EGradientColor.PALE_BLUE]: (_a = palette.gradients) === null || _a === void 0 ? void 0 : _a.paleBlue,
        [EGradientColor.PALE_RED]: (_b = palette.gradients) === null || _b === void 0 ? void 0 : _b.paleRed,
        [EGradientColor.PALE_GREEN]: (_c = palette.gradients) === null || _c === void 0 ? void 0 : _c.paleGreen,
        [EGradientColor.PALE_ORANGE]: (_d = palette.gradients) === null || _d === void 0 ? void 0 : _d.paleOrange,
    })
        || {
            // 4TS Fallback color, since `PaletteOptions` properties are optional, but the actual implementation uses `PaletteOptions` with non-optional properties.
            start: "#90c3f5",
            end: "#63aef8",
            contrast: grayShades[9],
        };
};
