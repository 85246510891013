import {ETKUserAuthentication} from "server-app/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {SignInPage} from "../pages/SignInPage";

export const routeSignInPage: IAppRoute = {
  title: 'Sign-in',
  titleTk: ETKUserAuthentication.SIGN_IN,
  routePath: '/sign-in',
  getRoutePath: () => routeSignInPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => <SignInPage/>,
};
