import {IAppRoute} from "../../application/config/IAppRoute";
import {ShortURLLoader} from "../pages/ShortURLLoader";

export const routeShortUrlLoader: IAppRoute<{ shortId: string }> = {
  title: 'Short URL loader',
  routePath: '/go/:shortId',
  getRoutePath: ({shortId} = {shortId: ''}) => `/go/${shortId}`,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: ({pathParams: {shortId}}) => (
    <ShortURLLoader shortId={shortId}/>
  ),
};
