import {ETKAppCore} from "server-app/dist/interfaces";

import {IAppRoute} from "../config/IAppRoute";
import {AppSettings} from "../pages/AppSettingsPage";

import SettingsIcon from '@mui/icons-material/Tune';

export const routeAppSettingsPage: IAppRoute = {
  menuId: 'options',
  title: 'Application settings',
  titleTk: ETKAppCore.APP_SETTINGS,
  description: 'Application and user settings',
  icon: <SettingsIcon/>,
  routePath: '/settings',
  getRoutePath: () => routeAppSettingsPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => <AppSettings/>,
};
